<template>
  <div>
    <v-dialog :value="value" @input="$emit('input', $event)" max-width="600">
      <v-card class="pt-4">
        <v-form @submit.prevent="save">
          <v-card-title>
            <span class="text-h5">{{ link ? $locale.editLink : $locale.addLink }}</span>
          </v-card-title>

          <v-card-text>
            <v-container class="pa-0">
              <v-row>
                <v-col cols="12">
                  <v-text-field :label="$locale.inputLinkName" outlined hide-details="auto" v-model="linkData.name" />
                </v-col>
                <v-col cols="12">
                  <v-text-field :label="$locale.inputLinkUrl" outlined hide-details="auto" type="url" v-model="linkData.url" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn large depressed @click="cancel" class="px-4">
              {{ $locale.cancel }}
            </v-btn>
            <v-btn large depressed color="primary" type="submit" class="px-4" :loading="isBeingSaved">
              {{ link ? $locale.save : $locale.add }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snack" :timeout="5000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" @click="snack = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { ADD_LINK, EDIT_LINK } from '@/store/links/types';

export default {
  name: "PopupFormLink",
  data: () => ({
    linkData: {
      id: '',
      name: '',
      url: '',
    },
    isBeingSaved: false,
    snack: false,
    snackColor: '',
    snackText: '',
  }),
  props: {
    value: {
      type: Boolean,
      default: null,
    },
    link: {
      type: Object,
    },
  },
  methods: {
    async save() {
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   return false;
      // }

      this.isBeingSaved = true;

      const link = { ...this.linkData };

      if (this.isModeNew) {
        try {
          await this.$store.dispatch(ADD_LINK, link);
          this.snack = true;
          this.snackColor = 'success';
          this.snackText = this.$locale.successДштлAdd;

          this.linkData.name = '';
          this.linkData.url = '';
          this.$emit('input', false);
        } catch (e) {
          this.snack = true;
          this.snackColor = 'error';
          this.snackText = this.$locale.errorBasic;
        }
      } else {
        try {
          link.id = this.link.id;
          await this.$store.dispatch(EDIT_LINK, link);
          this.snack = true;
          this.snackColor = 'success';
          this.snackText = this.$locale.successChangesSaved;
          this.$emit('input', false);
        } catch (e) {
          this.snack = true;
          this.snackColor = 'error';
          this.snackText = this.$locale.errorBasic;
        }
      }
      
      this.isBeingSaved = false;
    },
    cancel() {
      this.$emit('input', false);
    },
  },
  computed: {
    isModeNew() {
      return !this.link;
    },
  },
  watch: {
    link: {
      immediate: true,
      deep: true,
      handler() {
        if (this.link) {
          this.linkData.name = this.link.name;
          this.linkData.url = this.link.url;
        } else {
          this.linkData.name = '';
          this.linkData.url = '';
        }
      },
    },
  },
};
</script>

<style>
</style>