import Vue from 'vue';

const locale = {
  projectName: 'Ліцей "Основа"',
  projectNameFull: 'Вишнівський академічний ліцей "Основа" Вишневої міської ради Бучанського району Київської області',
  addMenuList: {
    page: 'Сторінку',
    link: 'Посилання',
    folder: 'Папку',
  },
  authFormTitle: 'Авторизація',
  authFormWrongCredentials: 'Невірний логін або пароль',
  inputUsername: 'Логін',
  inputPassword: 'Пароль',
  signin: 'Увійти',
  add: 'Додати',
  edit: 'Редагувати',
  save: 'Зберегти',
  cancel: 'Відмінити',
  select: 'Вибрати',
  delete: 'Видалити',
  saveChanges: 'Зберегти зміни',
  addPage: 'Додати сторінку',
  editPage: 'Редагувати сторінку',
  inputPageName: 'Назва',
  inputPageText: 'Контент сторінки',
  addArticle: 'Додати новину',
  editArticle: 'Редагувати новину',
  inputArticleName: 'Назва',
  inputArticlePreview: 'Анонс',
  inputArticleText: 'Текст новини',
  inputArticleDate: 'Дата публікації',
  inputArticlePinned: 'Закріпити?',
  inputArticleImage: 'Вибрати зображення',
  news: 'Новини',
  newsFilterByDateLabel: 'Виберіть дату',
  readMore: 'Читати',
  showMore: 'Показати ще',
  addFolder: 'Додати папку',
  editFolder: 'Редагувати папку',
  inputFolderName: 'Назва',
  addLink: 'Додати посилання',
  editLink: 'Редагувати посилання',
  inputLinkName: 'Назва',
  inputLinkUrl: 'Посилання',

  errorBasic: 'Упс, щось пішло не так!',
  error404Title: '404',
  error404Description: 'Сторінка не знайдена',
  errorOops: 'Упс',

  successChangesSaved: 'Зміни успішно збережено!',
  successPageAdd: 'Сторінка успішно додана!',
  successArticleAdd: 'Новина успішно додана!',
  successFolderAdd: 'Папка успішно додана!',
  successLinkAdd: 'Посилання успішно додано!',

  confirmDeleteArticle: 'Видалити цю новину?',
  confirmDeleteFolder: 'Видалити цю папку?',
  confirmDeleteLink: 'Видалити це посиланне?',
  confirmDeletePage: 'Видалити цю сторінку?',

};

Vue.use({
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$locale = locale;
  },
});


