import Vue from 'vue';
import Vuetify from 'vuetify';
import colors from 'vuetify/lib/util/colors'
import 'vuetify/dist/vuetify.min.css';
import uk from 'vuetify/lib/locale/uk'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        // accent: colors.orange.darken1,
        accent: colors.deepPurple,
        primary: colors.lightGreen.darken2,
        subtle: colors.grey.lighten4,
      },
    },
    options: { customProperties: true },
  },
  lang: {
    locales: { uk },
    current: 'uk',
  },
});