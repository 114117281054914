<template>
  <v-dialog :value="value" @input="$emit('input', $event)" max-width="500px">
    <v-card :loading="isArticleBeingDeleted">
      <v-card-title class="pt-5 pb-5">{{ $locale.confirmDeleteArticle }}</v-card-title>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn large depressed @click="closeDeleteArticle" class="px-4">{{ $locale.cancel }}</v-btn>
        <v-btn color="error" large depressed @click="deleteArticle" class="px-4">{{ $locale.delete }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DELETE_ARTICLE } from '@/store/news/types';

export default {
  data: () => ({
    isArticleBeingDeleted: false,
  }),
  props: ['article', 'value'],
  methods: {
    async deleteArticle() {
			this.isArticleBeingDeleted = true;
			await this.$store.dispatch(DELETE_ARTICLE, this.article.id);
      this.$emit('deleted');
			this.closeDeleteArticle();
		},
		closeDeleteArticle() {
      this.isArticleBeingDeleted = false;
			this.$emit('input', false);
      this.$emit('close');
		},
  },
}
</script>

<style>

</style>