export const GET_LINK = 'GET_LINK';
export const GET_LINK_SUCCESS = 'GET_LINK_SUCCESS';
export const GET_LINK_ERROR = 'GET_LINK_ERROR';

export const ADD_LINK = 'ADD_LINK';
export const ADD_LINK_SUCCESS = 'ADD_LINK_SUCCESS';
export const ADD_LINK_ERROR = 'ADD_LINK_ERROR';

export const EDIT_LINK = 'EDIT_LINK';
export const EDIT_LINK_SUCCESS = 'EDIT_LINK_SUCCESS';
export const EDIT_LINK_ERROR = 'EDIT_LINK_ERROR';

export const DELETE_LINK = 'DELETE_LINK';
export const DELETE_LINK_SUCCESS = 'DELETE_LINK_SUCCESS';
export const DELETE_LINK_ERROR = 'DELETE_LINK_ERROR';

export const CLEAR_LINK_STATE = 'CLEAR_LINK_STATE';
