export const GET_FOLDER = 'GET_FOLDER';
export const GET_FOLDER_SUCCESS = 'GET_FOLDER_SUCCESS';
export const GET_FOLDER_ERROR = 'GET_FOLDER_ERROR';

export const ADD_FOLDER = 'ADD_FOLDER';
export const ADD_FOLDER_SUCCESS = 'ADD_FOLDER_SUCCESS';
export const ADD_FOLDER_ERROR = 'ADD_FOLDER_ERROR';

export const EDIT_FOLDER = 'EDIT_FOLDER';
export const EDIT_FOLDER_SUCCESS = 'EDIT_FOLDER_SUCCESS';
export const EDIT_FOLDER_ERROR = 'EDIT_FOLDER_ERROR';

export const DELETE_FOLDER = 'DELETE_FOLDER';
export const DELETE_FOLDER_SUCCESS = 'DELETE_FOLDER_SUCCESS';
export const DELETE_FOLDER_ERROR = 'DELETE_FOLDER_ERROR';

export const CLEAR_FOLDER_STATE = 'CLEAR_FOLDER_STATE';
