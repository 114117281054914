<template>
  <div>
    <v-dialog :value="value" @input="$emit('input', $event)" max-width="600">
      <v-card class="pt-4">
        <v-form @submit.prevent="save">
          <v-card-title>
            <span class="text-h5">{{ folder ? $locale.editFolder : $locale.addFolder }}</span>
          </v-card-title>

          <v-card-text>
            <v-container class="pa-0">
              <v-row>
                <v-col cols="12">
                  <v-text-field :label="$locale.inputFolderName" outlined hide-details="auto" v-model="folderData.name" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn large depressed @click="cancel" class="px-4">
              {{ $locale.cancel }}
            </v-btn>
            <v-btn large depressed color="primary" type="submit" class="px-4" :loading="isBeingSaved">
              {{ folder ? $locale.save : $locale.add }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snack" :timeout="5000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" @click="snack = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { ADD_FOLDER, EDIT_FOLDER } from '@/store/folders/types';

export default {
  name: "PopupFormFolder",
  data: () => ({
    folderData: {
      name: '',
    },
    isBeingSaved: false,
    snack: false,
    snackColor: '',
    snackText: '',
  }),
  props: {
    value: {
      type: Boolean,
      default: null,
    },
    folder: {
      type: Object,
    },
  },
  methods: {
    async save() {
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   return false;
      // }

      this.isBeingSaved = true;

      const folder = { ...this.folderData };

      if (this.isModeNew) {
        try {
          await this.$store.dispatch(ADD_FOLDER, folder);
          this.snack = true;
          this.snackColor = 'success';
          this.snackText = this.$locale.successFolderAdd;

          this.folderData.name = '';
          this.$emit('input', false);
        } catch (e) {
          this.snack = true;
          this.snackColor = 'error';
          this.snackText = this.$locale.errorBasic;
        }
      } else {
        try {
          folder.id = this.folder.id;
          await this.$store.dispatch(EDIT_FOLDER, folder);
          this.snack = true;
          this.snackColor = 'success';
          this.snackText = this.$locale.successChangesSaved;
          this.$emit('input', false);
        } catch (e) {
          this.snack = true;
          this.snackColor = 'error';
          this.snackText = this.$locale.errorBasic;
        }
      }
      
      this.isBeingSaved = false;
    },
    cancel() {
      this.$emit('input', false);
    },
  },
  computed: {
    isModeNew() {
      return !this.folder;
    },
  },
  watch: {
    folder: {
      immediate: true,
      deep: true,
      handler() {
        if (this.folder) {
          this.folderData.name = this.folder.name;
        } else {
          this.folderData.name = '';
        }
      },
    },
  },
};
</script>

<style>
</style>