import Vue from 'vue';
import Vuex from 'vuex';

import app from './app/index';
import auth from './auth/index';
import pages from './pages/index';
import news from './news/index';
import links from './links/index';
import folders from './folders/index';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    auth,
    news,
    links,
    pages,
    folders,
  },
});
