import axios from 'axios';
import {
  GET_MENU,
  GET_MENU_SUCCESS,
  GET_MENU_ERROR,
  UPDATE_MENU,
  UPDATE_MENU_SUCCESS,
  UPDATE_MENU_ERROR,
  TOGGLE_PAGE_LOADER,
  TOGGLE_PAGE_404,
} from './types';

const apiBaseURL = process.env.VUE_APP_API_URL;

const getMenu = async ({ commit }) => {
  commit(GET_MENU);
  try {
    const { data } = await axios.get(`${apiBaseURL}/menu`);
    commit(GET_MENU_SUCCESS, data);
  } catch (e) {
    commit(GET_MENU_ERROR, e);
  }
};

const updateMenu = async ({ commit }, menu) => {
  commit(UPDATE_MENU);
  try {
    const { data } = await axios.post(`${apiBaseURL}/menu`, menu);
    commit(UPDATE_MENU_SUCCESS, data);
    return Promise.resolve();
  } catch (e) {
    commit(UPDATE_MENU_ERROR, e);
    return Promise.reject(e);
  }
};

const togglePageLoader = async ({ commit }, payload) => {
  commit(TOGGLE_PAGE_LOADER, payload);
};

const togglePage404 = async ({ commit }, payload) => {
  commit(TOGGLE_PAGE_404, payload);
};

export default {
  [GET_MENU]: getMenu,
  [UPDATE_MENU]: updateMenu,
  [TOGGLE_PAGE_LOADER]: togglePageLoader,
  [TOGGLE_PAGE_404]: togglePage404,
};
