import axios from 'axios';
import {
  GET_NEWS,
  GET_NEWS_SUCCESS,
  GET_NEWS_ERROR,
  LOAD_NEWS,
  LOAD_NEWS_SUCCESS,
  LOAD_NEWS_ERROR,
  GET_ARTICLE,
  GET_ARTICLE_SUCCESS,
  GET_ARTICLE_ERROR,
  ADD_ARTICLE,
  ADD_ARTICLE_SUCCESS,
  ADD_ARTICLE_ERROR,
  EDIT_ARTICLE,
  EDIT_ARTICLE_SUCCESS,
  EDIT_ARTICLE_ERROR,
  DELETE_ARTICLE,
  DELETE_ARTICLE_SUCCESS,
  DELETE_ARTICLE_ERROR,
  CLEAR_ARTICLE_STATE,
} from './types';
import { TOGGLE_PAGE_404 } from '../app/types';

const apiBaseURL = process.env.VUE_APP_API_URL;

const getNews = async ({ commit }, { page, date }) => {
  commit(GET_NEWS);
  try {
    const { data } = await axios.get(`${apiBaseURL}/news?page=${page || 1}&date=${date}`);
    commit(GET_NEWS_SUCCESS, data);
    return Promise.resolve();
  } catch (e) {
    commit(GET_NEWS_ERROR, e);
    return Promise.reject();
  }
};

const loadNews = async ({ commit }, { page, date }) => {
  commit(LOAD_NEWS);
  try {
    const { data } = await axios.get(`${apiBaseURL}/news?page=${page || 1}&date=${date}`);
    commit(LOAD_NEWS_SUCCESS, data);
    return Promise.resolve();
  } catch (e) {
    commit(LOAD_NEWS_ERROR, e);
    return Promise.reject();
  }
};

const getArticle = async ({ commit, dispatch }, id) => {
  commit(GET_ARTICLE);
  try {
    const { data } = await axios.get(`${apiBaseURL}/news/${id}`);
    commit(GET_ARTICLE_SUCCESS, data);
  } catch (e) {
    commit(GET_ARTICLE_ERROR, e);
    if(e.response.status) {
      dispatch(TOGGLE_PAGE_404, true);
    }
  }
};

const addArticle = async ({ commit }, article) => {
  commit(ADD_ARTICLE);
  try {
    const { data } = await axios.post(`${apiBaseURL}/news`, article);
    commit(ADD_ARTICLE_SUCCESS, data);
    return Promise.resolve();
  } catch (e) {
    commit(ADD_ARTICLE_ERROR, e);
    return Promise.reject(e);
  }
};

const editArticle = async ({ commit }, article) => {
  commit(EDIT_ARTICLE);
  try {
    const { data } = await axios.post(`${apiBaseURL}/news/${article.id}`, {
      ...article,
      _method: 'PUT',
    });
    commit(EDIT_ARTICLE_SUCCESS, data);
    return Promise.resolve();
  } catch (e) {
    commit(EDIT_ARTICLE_ERROR, e);
    return Promise.reject(e);
  }
};

const deleteArticle = async ({ commit }, id) => {
  commit(DELETE_ARTICLE);
  try {
    const { data } = await axios.post(`${apiBaseURL}/news/${id}`, { _method: 'DELETE' });
    commit(DELETE_ARTICLE_SUCCESS, data);
  } catch (e) {
    commit(DELETE_ARTICLE_ERROR, e);
  }
};

const clearArticleState = async ({ commit }) => {
  commit(CLEAR_ARTICLE_STATE);
};

export default {
  [GET_NEWS]: getNews,
  [LOAD_NEWS]: loadNews,
  [GET_ARTICLE]: getArticle,
  [ADD_ARTICLE]: addArticle,
  [EDIT_ARTICLE]: editArticle,
  [DELETE_ARTICLE]: deleteArticle,
  [CLEAR_ARTICLE_STATE]: clearArticleState,
};
