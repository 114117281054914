import {
  GET_PAGE,
  GET_PAGE_SUCCESS,
  GET_PAGE_ERROR,
  ADD_PAGE,
  ADD_PAGE_SUCCESS,
  ADD_PAGE_ERROR,
  EDIT_PAGE,
  EDIT_PAGE_SUCCESS,
  EDIT_PAGE_ERROR,
  DELETE_PAGE,
  DELETE_PAGE_SUCCESS,
  DELETE_PAGE_ERROR,
  CLEAR_PAGE_STATE,
} from './types';

export const getters = {
  page: (state) => state.page,
  pageStatus: (state) => state.pageStatus,
  addPageStatus: (state) => state.addPageStatus,
  editPageStatus: (state) => state.editPageStatus,
  deletePageStatus: (state) => state.deletePageStatus,
};

export const mutations = {
  [GET_PAGE]: (state) => {
    state.pageStatus = 'loading';
  },
  [GET_PAGE_SUCCESS]: (state, payload) => {
    state.page = payload.data;
    state.pageStatus = 'success';
  },
  [GET_PAGE_ERROR]: (state, e) => {
    state.pageStatus = e;
  },
  [ADD_PAGE]: (state) => {
    state.addPageStatus = 'loading';
  },
  [ADD_PAGE_SUCCESS]: (state) => {
    state.addPageStatus = 'success';
  },
  [ADD_PAGE_ERROR]: (state, e) => {
    state.addPageStatus = e;
  },
  [EDIT_PAGE]: (state) => {
    state.editPageStatus = 'loading';
  },
  [EDIT_PAGE_SUCCESS]: (state) => {
    state.editPageStatus = 'success';
  },
  [EDIT_PAGE_ERROR]: (state, e) => {
    state.editPageStatus = e;
  },
  [DELETE_PAGE]: (state) => {
    state.deletePageStatus = 'loading';
  },
  [DELETE_PAGE_SUCCESS]: (state) => {
    state.deletePageStatus = 'success';
  },
  [DELETE_PAGE_ERROR]: (state, e) => {
    state.deletePageStatus = e;
  },
  [CLEAR_PAGE_STATE]: (state) => {
    state.page = {};
    state.pageStatus = '';
    state.addPageStatus = '';
    state.editPageStatus = '';
    state.deletePageStatus = '';
  },
};

export const state = {
  page: {},
  pageStatus: '',
  addPageStatus: '',
  editPageStatus: '',
  deletePageStatus: '',
};
