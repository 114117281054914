<template>
  <v-sheet class="px-4 px-sm-6 px-md-8 py-4 container--fluid">
    <v-card class="mb-12">
      <v-carousel cycle hide-delimiters :height="isDisplayXs ? 350 : 450">
        <v-carousel-item
          v-for="article in pinnedNews"
          :key="article.id"
        >
          <v-card height="100%" :to="{ name: 'News', params: { id: article.id } }">
            <v-img  :src="article.image" height="100%" class="slider_item_image px-4 py-5 py-sm-10 white--text align-end"
                    gradient="to top, hsla(100deg, 20%, 10%, .5) 20%, transparent 80%">
              <h3 class="slider_item_title text-truncate text-h5 text-sm-h4 mb-2 mb-sm-3">{{ article.title }}</h3>
              <p class="slider_item_description text-caption text-sm-body-2">{{ article.preview }}</p>
              <span class="slider_item_date text-caption text-sm-body-2">
                {{ $dayjs(article.published_at).format('DD MMMM YYYY') }}
              </span>
            </v-img>
          </v-card>
        </v-carousel-item>
      </v-carousel>
    </v-card>
    <div class="news_header d-sm-flex align-center mb-4 mb-sm-0">
      <h2 class="text-h3 mb-2 primary--text">{{ $locale.news}}</h2>
      <v-dialog ref="dialog" v-model="newsFilterDatePopup" :return-value.sync="newsFilterDate" width="290px" >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field :value="newsFilterDateFormatted" :placeholder="$locale.newsFilterByDateLabel"
                        outlined readonly clearable prepend-inner-icon="mdi-calendar"
                        @click:clear="clearFilterByDate" v-bind="attrs" v-on="on" dense
                        class="news_header_dateFilter ml-sm-auto" :class="{'is-xs': isDisplayXs}" hide-details />
        </template>
        <v-date-picker v-model="newsFilterDate" scrollable no-title range>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="newsFilterDatePopup = false" >
            {{ $locale.cancel }}
          </v-btn>
          <v-btn text color="primary" @click="filterByDate" >
            {{ $locale.select }}
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </div>
    <v-container class="news px-0" fluid>
      <v-row>
        <v-col v-for="article in news" :key="article.id" cols="12" md="4" sm="6">
          <v-card height="100%" class="news_item d-flex flex-column" :to="{ name: 'News', params: { id: article.id } }" @click.self.stop>

            <v-img  v-if="isAdmin" :src="article.image" :aspect-ratio="16/9" class="news_item_image flex-grow-0 white--text"
                    gradient="to top, hsla(100deg, 20%, 10%, .5) 20%, transparent 80%">
              <div class="news_item_actions d-flex justify-end pa-4">
                <v-btn  fab x-small class="mr-2" :color="article.is_pinned ? 'accent' : ''"
                        @click.prevent.stop="toggleArticlePinned(article)">
                  <v-icon>mdi-pin-outline</v-icon>
                </v-btn>
                <v-btn fab x-small class="mr-2" :to="{ name: 'EditArticle', params: { id: article.id } }">
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn fab x-small @click.prevent.stop="deleteArticle(article)">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </div>
            </v-img>
            <v-img v-else :src="article.image" :aspect-ratio="16/9" class="news_item_image flex-grow-0" />

            <v-sheet color="primary" class="pt-1"></v-sheet>
            <v-card-title class="news_item_title">
              {{ article.title }}
            </v-card-title>
            <v-card-text class="pb-0">
              <p>{{ article.preview }}</p>
            </v-card-text>
            <v-card-actions class="mt-auto px-4 pb-4">
              <span class="text-caption text--secondary">
                {{ $dayjs(article.published_at).format('DD MMM YYYY') }}
              </span>
              <v-btn depressed text small color="accent" class="ml-auto">
                {{ $locale.readMore }} <v-icon size="18">mdi-chevron-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="6">
          <v-btn depressed large block class="mx-auto" @click="loadNews" v-if="newsMeta.current_page < newsMeta.last_page">
            {{ $locale.showMore }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div class="news_addBttn" v-if="isAdmin">
      <v-btn color="primary" elevation="2" fab :to="{ name: 'AddArticle' }">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <PopupConfirmDeleteArticle
      v-if="isAdmin"
      v-model="isPopupConfirmDeleteArticleVisible"
      :article="articleToDelete"
      @deleted="getNews"
    />
  </v-sheet>
</template>

<script>
import PopupConfirmDeleteArticle from "@/components/PopupConfirmDeleteArticle.vue";
import { GET_NEWS, LOAD_NEWS, EDIT_ARTICLE } from '@/store/news/types';
import { TOGGLE_PAGE_LOADER } from '@/store/app/types';

export default {
  name: 'Home',
  data: () => ({
    newsFilterDate: [],
    newsFilterDatePopup: null,
    isPopupConfirmDeleteArticleVisible: false,
    articleToDelete: "",
  }),
  components: {
    PopupConfirmDeleteArticle,
  },
  computed: {
    isDisplayXs() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    newsFilterDateFormatted() {
      const dates = [...this.newsFilterDate].sort();
      return dates.map(item => this.$dayjs(item).format('DD.MM.YYYY')).join(' - ');
    },
    newsFilterDateFormattedISO() {
      const dates = [...this.newsFilterDate].sort();
      return dates.map(item => this.$dayjs(item).format('YYYY-MM-DD')).join('/');
    },
    news() {
      return this.$store.getters.news.list;
    },
    pinnedNews() {
      return this.$store.getters.news.pinned;
    },
    newsMeta() {
      return this.$store.getters.news.meta;
    },
    newsStatus() {
      return this.$store.getters.newsStatus;
    },
    isAdmin() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    loadNews() {
      this.$store.dispatch(LOAD_NEWS, {
        date: this.newsFilterDateFormattedISO,
        page: this.newsMeta.current_page + 1,
      });
    },
    async getNews() {
      await this.$store.dispatch(GET_NEWS, {
        date: this.newsFilterDateFormattedISO,
      });
    },
    async toggleArticlePinned(article) {
      await this.$store.dispatch(EDIT_ARTICLE, {
        id: article.id,
        is_pinned: !article.is_pinned,
      });

      article.is_pinned = !article.is_pinned;
    },
    filterByDate() {
      this.$refs.dialog.save(this.newsFilterDate);
      this.getNews();
    },
    clearFilterByDate() {
      this.newsFilterDate = [];
      this.getNews();
    },
    deleteArticle($event) {
      this.articleToDelete = $event;
      this.isPopupConfirmDeleteArticleVisible = true;
    },
  },
  created() {
    this.$store.dispatch(TOGGLE_PAGE_LOADER, true);

    this.getNews().then(() => {
      this.$store.dispatch(TOGGLE_PAGE_LOADER, false);
    });
  },
}
</script>

<style lang="scss" scoped>
.slider_item_image {
  background: url(../assets/images/logo.svg) center / clamp(100px, 25%, 150px) auto no-repeat, var(--v-subtle-base);
}
.slider_item_description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; 
  max-width: 550px;
  overflow: hidden;
}
.slider_item_date {
  opacity: 0.7;
}

.news_header_dateFilter {
  max-width: 260px;

  &.is-xs {
    max-width: unset;
  }
}

::v-deep {
  .news_item {

    .v-image__image {
      transition: .35s ease;
    }
    &:hover {
      .v-image__image {
        transform: scale(1.1);
      }
    }
  }
  .news_item_title {
    word-break: unset;
  }
  .news_item_image {
    background: url(../assets/images/logo.svg) center / clamp(60px, 25%, 100px) auto no-repeat, var(--v-subtle-base);
  }
}
.news_addBttn {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 24px;
  padding: 16px;
  pointer-events: none;

  .v-btn {
    pointer-events: all;
  }
}
</style>
