import {
  GET_LINK,
  GET_LINK_SUCCESS,
  GET_LINK_ERROR,
  ADD_LINK,
  ADD_LINK_SUCCESS,
  ADD_LINK_ERROR,
  EDIT_LINK,
  EDIT_LINK_SUCCESS,
  EDIT_LINK_ERROR,
  DELETE_LINK,
  DELETE_LINK_SUCCESS,
  DELETE_LINK_ERROR,
  CLEAR_LINK_STATE,
} from './types';

export const getters = {
  link: (state) => state.link,
  linkStatus: (state) => state.linkStatus,
  addLinkStatus: (state) => state.addLinkStatus,
  editLinkStatus: (state) => state.editLinkStatus,
  deleteLinkStatus: (state) => state.deleteLinkStatus,
};

export const mutations = {
  [GET_LINK]: (state) => {
    state.linkStatus = 'loading';
  },
  [GET_LINK_SUCCESS]: (state, payload) => {
    state.link = payload.data;
    state.linkStatus = 'success';
  },
  [GET_LINK_ERROR]: (state, e) => {
    state.linkStatus = e;
  },
  [ADD_LINK]: (state) => {
    state.addLinkStatus = 'loading';
  },
  [ADD_LINK_SUCCESS]: (state) => {
    state.addLinkStatus = 'success';
  },
  [ADD_LINK_ERROR]: (state, e) => {
    state.addLinkStatus = e;
  },
  [EDIT_LINK]: (state) => {
    state.editLinkStatus = 'loading';
  },
  [EDIT_LINK_SUCCESS]: (state) => {
    state.editLinkStatus = 'success';
  },
  [EDIT_LINK_ERROR]: (state, e) => {
    state.editLinkStatus = e;
  },
  [DELETE_LINK]: (state) => {
    state.deleteLinkStatus = 'loading';
  },
  [DELETE_LINK_SUCCESS]: (state) => {
    state.deleteLinkStatus = 'success';
  },
  [DELETE_LINK_ERROR]: (state, e) => {
    state.deleteLinkStatus = e;
  },
  [CLEAR_LINK_STATE]: (state) => {
    state.link = {};
    state.linkStatus = '';
    state.addLinkStatus = '';
    state.editLinkStatus = '';
    state.deleteLinkStatus = '';
  },
};

export const state = {
  link: {},
  linkStatus: '',
  addLinkStatus: '',
  editLinkStatus: '',
  deleteLinkStatus: '',
};
