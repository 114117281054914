<template>
  <draggable  tag="ul" :list="items" :group="groupOptions" :data-level="level" :data-type="type" :disabled="!allowDrag"
              :style="{'--level': level + 1}" :class="['nav_group', 'pl-0', { 'pb-4': type === 'main'}]">
    <li v-for="item in items" :key="`${item.type}${item.id}`" :data-type="item.type" :class="['nav_item', { 'is-opened': item.isOpened }]">
      <component
        :is="itemTag(item)"
        v-on="itemListeners(item)"
        v-bind="itemAttrs(item)"
        class="nav_item_header">
        <span class="nav_item_header_title">{{ item.name }}</span>
        <div class="nav_item_header_actions">
          <v-icon v-if="item.type === 'folder'" class="nav_item_header_arrow mr-1">mdi-chevron-right</v-icon>
          <v-btn v-if="isAdmin && !item.is_stable && item.type !== 'logout'" icon depressed small v-on="itemEditListeners(item)">
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn v-if="isAdmin && !item.is_stable && item.type !== 'logout'" icon depressed small @click.prevent.stop="$emit(`delete-${item.type}`, item)"
                :disabled="!!(item.items && item.items.length)">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
          <v-btn icon depressed small disabled v-if="item.type === 'logout'">
            <v-icon>mdi-location-exit</v-icon>
          </v-btn>
        </div>
      </component>

      <v-expand-transition>
        <div v-show="item.isOpened">
          <NavItem
            :items="item.items"
            :level="level+1"
            :type="item.type"
            :allowDrag="allowDrag"
            :data-id="item.id"
            :isAdmin="isAdmin"
            @delete-link="$emit('delete-link', $event)"
            @delete-folder="$emit('delete-folder', $event)"
            @delete-page="$emit('delete-page', $event)"
            @editLink="$emit('editLink', $event)"
            @editFolder="$emit('editFolder', $event)"
          />
        </div>
      </v-expand-transition>
    </li>
  </draggable>
</template>

<script>
import { LOGOUT } from '@/store/auth/types';

export default {
  name: 'NavItem',
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    level: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: 'main',
    },
    allowDrag: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
    },
  },
  data: () => ({
    groupOptions: {
      name: 'nav',
      pull(to, from, el) {
        const level = to.el.dataset.level;
        const typeTo = to.el.dataset.type;
        const typeEl = el.dataset.type;

        if (typeTo === 'page') {
          return false;
        }
        if (typeEl === 'folder') {
          return level < 2;
        }
        return level < 3;
      },
    },
  }),
  methods: {
    itemTag(item) {
      switch(item.type) {
        case 'page':
          return 'router-link';

        case 'link':
          return item.url.indexOf('/') === 0 ? 'router-link' : 'a';

        default:
          return 'div';
      }
    },
    itemAttrs(item) {
      switch(item.type) {
        case 'page':
          return {
            to: item.link || { name: 'Page', params: { id: item.id } },
          };

        case 'link':
          if (item.url.indexOf('/') === 0) {
            return {
              to: item.url,
            };
          }
          return {
            href: item.url,
            target: "_blank",
            rel: "noopener noreferrer",
          };

        default:
          return {};
      }
    },
    itemListeners(item) {
      switch(item.type) {
        case 'page':
          return {};

        case 'link':
          return {};

        case 'folder':
          return {
            click: () => {
              item.isOpened = !item.isOpened;
            },
            dragenter: () => {
              item.isOpened = true;
            },
          }

        case 'logout':
          return {
            click: () => {
              this.$store.dispatch(LOGOUT).catch(() => {});
            },
          };

        default:
          return {};
      }
    },
    itemEditListeners(item) {
      switch(item.type) {
        case 'page':
          return {
            click: (e) => {
              e.preventDefault();
              this.$router.push({
                name: 'EditPage',
                params: {
                  id: item.id,
                },
              });
            },
          };

        case 'link':
          return {
            click: (e) => {
              e.preventDefault();
              this.$emit('editLink', item);
            },
          };

        case 'folder':
          return {
            click: (e) => {
              e.preventDefault();
              e.stopPropagation();
              this.$emit('editFolder', item);
            },
          };

        default:
          return {
            click: (e) => {
              e.preventDefault();
            },
          };
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.nav_item_header {
  align-items: center;
  display: flex;
  min-height: 48px;
  padding: .5em 24px .5em calc(24px * var(--level));
  position: relative;
  color: currentColor;
  text-decoration: none;
  line-height: 1.15em;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: currentColor;
    opacity: 0;
    transition: .3s cubic-bezier(.25,.8,.5,1);
  }
  &:hover {
    &::before {
      opacity: 0.04;
    }
  }
}
.nav_item_header_actions {
  flex-shrink: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
}
.nav_item_header_arrow {
  transition: .3s cubic-bezier(.25,.8,.5,1);

  .nav_item.is-opened > .nav_item_header & {
    transform: rotate(90deg);
  }
}
</style>