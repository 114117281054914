<template>
  <v-overlay :value="visible" color="white" opacity="1" :class="['pageLoader', { 'is-visible': visible }]">
    <v-progress-circular indeterminate size="64" color="primary" />
  </v-overlay>
</template>

<script>
export default {
  props: ['visible'],
}
</script>

<style lang="scss" scoped>
.pageLoader {
  z-index: 50!important;
  
  &.is-visible {
    transition: none;
  }
}
</style>