export const GET_MENU = 'GET_MENU';
export const GET_MENU_SUCCESS = 'GET_MENU_SUCCESS';
export const GET_MENU_ERROR = 'GET_MENU_ERROR';

export const UPDATE_MENU = 'UPDATE_MENU';
export const UPDATE_MENU_SUCCESS = 'UPDATE_MENU_SUCCESS';
export const UPDATE_MENU_ERROR = 'UPDATE_MENU_ERROR';

export const TOGGLE_PAGE_LOADER = 'TOGGLE_PAGE_LOADER';
export const TOGGLE_PAGE_404 = 'TOGGLE_PAGE_404';
