import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Home from '@/views/Home.vue';
import { TOGGLE_PAGE_404 } from '@/store/app/types';
const Article = () => import(/* webpackChunkName: "article" */ '../views/Article.vue');
const Page = () => import(/* webpackChunkName: "page" */ '../views/Page.vue');
const FormPage = () => import(/* webpackChunkName: "formpage" */ '../views/FormPage.vue');
const FormArticle = () => import(/* webpackChunkName: "formarticle" */ '../views/FormArticle.vue');
const Error404 = () => import(/* webpackChunkName: "error404" */ '../views/Error404.vue');

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/page/new',
    name: 'AddPage',
    component: FormPage,
    meta: {
      authOnly: true,
    },
  },
  {
    path: '/page/:id/edit',
    name: 'EditPage',
    component: FormPage,
    props: true,
    meta: {
      authOnly: true,
    },
  },
  {
    path: '/page/:id',
    name: 'Page',
    component: Page,
    props: true,
  },
  {
    path: '/news/new',
    name: 'AddArticle',
    component: FormArticle,
    meta: {
      authOnly: true,
    },
  },
  {
    path: '/news/:id/edit',
    name: 'EditArticle',
    component: FormArticle,
    props: true,
    meta: {
      authOnly: true,
    },
  },
  {
    path: '/news/:id',
    name: 'News',
    component: Article,
    props: true,
  },
  {
    path: '*',
    name: 'Error404',
    component: Error404,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return { selector: to.hash }
    } else {
      return { 
        x: 0,
        y: 0,
      };
    }
  },
});

router.beforeEach((to, from, next) => {
  store.dispatch(TOGGLE_PAGE_404, false);
  if (to.matched.some((record) => record.meta.authOnly) && !store.getters.isAuthenticated) {
    next({ name: 'Home' });
  } else {
    next();
  }
});

export default router;
