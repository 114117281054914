import dayjs  from 'dayjs';
import 'dayjs/locale/uk';

dayjs.locale('uk');

export default {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$dayjs = dayjs;
  },
};
