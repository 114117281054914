import {
  GET_FOLDER,
  GET_FOLDER_SUCCESS,
  GET_FOLDER_ERROR,
  ADD_FOLDER,
  ADD_FOLDER_SUCCESS,
  ADD_FOLDER_ERROR,
  EDIT_FOLDER,
  EDIT_FOLDER_SUCCESS,
  EDIT_FOLDER_ERROR,
  DELETE_FOLDER,
  DELETE_FOLDER_SUCCESS,
  DELETE_FOLDER_ERROR,
  CLEAR_FOLDER_STATE,
} from './types';

export const getters = {
  folder: (state) => state.folder,
  folderStatus: (state) => state.folderStatus,
  addFolderStatus: (state) => state.addFolderStatus,
  editFolderStatus: (state) => state.editFolderStatus,
  deleteFolderStatus: (state) => state.deleteFolderStatus,
};

export const mutations = {
  [GET_FOLDER]: (state) => {
    state.folderStatus = 'loading';
  },
  [GET_FOLDER_SUCCESS]: (state, payload) => {
    state.folder = payload.data;
    state.folderStatus = 'success';
  },
  [GET_FOLDER_ERROR]: (state, e) => {
    state.folderStatus = e;
  },
  [ADD_FOLDER]: (state) => {
    state.addFolderStatus = 'loading';
  },
  [ADD_FOLDER_SUCCESS]: (state) => {
    state.addFolderStatus = 'success';
  },
  [ADD_FOLDER_ERROR]: (state, e) => {
    state.addFolderStatus = e;
  },
  [EDIT_FOLDER]: (state) => {
    state.editFolderStatus = 'loading';
  },
  [EDIT_FOLDER_SUCCESS]: (state) => {
    state.editFolderStatus = 'success';
  },
  [EDIT_FOLDER_ERROR]: (state, e) => {
    state.editFolderStatus = e;
  },
  [DELETE_FOLDER]: (state) => {
    state.deleteFolderStatus = 'loading';
  },
  [DELETE_FOLDER_SUCCESS]: (state) => {
    state.deleteFolderStatus = 'success';
  },
  [DELETE_FOLDER_ERROR]: (state, e) => {
    state.deleteFolderStatus = e;
  },
  [CLEAR_FOLDER_STATE]: (state) => {
    state.folder = {};
    state.folderStatus = '';
    state.addFolderStatus = '';
    state.editFolderStatus = '';
    state.deleteFolderStatus = '';
  },
};

export const state = {
  folder: {},
  folderStatus: '',
  addFolderStatus: '',
  editFolderStatus: '',
  deleteFolderStatus: '',
};
