import axios from 'axios';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from '@/plugins/vuetify';
import dayjs from '@/plugins/dayjs';
import { LOGOUT } from './store/auth/types';
import '@/plugins/draggable';
import '@/plugins/ckeditor';
import '@/plugins/locale';

Vue.use(VueMeta);
Vue.use(dayjs);

Vue.config.productionTip = false;

const token = localStorage.getItem('authToken');
if (token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      store.dispatch(LOGOUT);
    }
    return Promise.reject(error);
  }
);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
