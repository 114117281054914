export const GET_PAGE = 'GET_PAGE';
export const GET_PAGE_SUCCESS = 'GET_PAGE_SUCCESS';
export const GET_PAGE_ERROR = 'GET_PAGE_ERROR';

export const ADD_PAGE = 'ADD_PAGE';
export const ADD_PAGE_SUCCESS = 'ADD_PAGE_SUCCESS';
export const ADD_PAGE_ERROR = 'ADD_PAGE_ERROR';

export const EDIT_PAGE = 'EDIT_PAGE';
export const EDIT_PAGE_SUCCESS = 'EDIT_PAGE_SUCCESS';
export const EDIT_PAGE_ERROR = 'EDIT_PAGE_ERROR';

export const DELETE_PAGE = 'DELETE_PAGE';
export const DELETE_PAGE_SUCCESS = 'DELETE_PAGE_SUCCESS';
export const DELETE_PAGE_ERROR = 'DELETE_PAGE_ERROR';

export const CLEAR_PAGE_STATE = 'CLEAR_PAGE_STATE';
