<template>
  <div>
    <NavItem :items="navItems" :allowDrag="isAdmin" :isAdmin="isAdmin"
              @delete-link="$emit('deleteLink', $event)"
              @delete-folder="$emit('deleteFolder', $event)"
              @delete-page="$emit('deletePage', $event)"
              @editLink="$emit('editLink', $event)"
              @editFolder="$emit('editFolder', $event)" />
    <NavItem v-if="isAdmin" :items="[{ name: 'Выход', type: 'logout' }]" class="mt-n4" :isAdmin="isAdmin" />
  </div>
</template>

<script>
import NavItem from '@/components/NavItem.vue';
import { GET_MENU, UPDATE_MENU } from '@/store/app/types';

export default {
  data: () => ({
    hasItemsLoaded: false,
  }),
  components: {
    NavItem,
  },
  props: ['isAdmin'],
  methods: {
    async getItems() {
      await this.$store.dispatch(GET_MENU);
      this.$nextTick(() => {
        this.hasItemsLoaded = true;
      });
    },
    async updateItems() {
      await this.$store.dispatch(UPDATE_MENU, {
        items: this.navItems,
      });
    },
  },
  computed: {
    navItems() {
      return this.$store.getters.menu;
    }
  },
  created() {
    this.getItems();
  },
  watch: {
    navItems: {
      deep: true,
      handler() {
        if (this.hasItemsLoaded) {
          this.updateItems();
        }
      },
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  ul {
    list-style: none;
  }
}
</style>