import axios from 'axios';
import router from '@/router';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  // PASSWORD_RECOVERY_REQUEST,
  // PASSWORD_RECOVERY_SUCCESS,
  // PASSWORD_RECOVERY_ERROR,
  LOGOUT,
} from './types';

const apiBaseURL = process.env.VUE_APP_API_URL;

const login = async ({ commit }, userData) => {
  commit(LOGIN_REQUEST);
  try {
    const { data } = await axios.post(`${apiBaseURL}/login`, userData);

    const { token } = data.data;
    
    localStorage.setItem('authToken', token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    commit(LOGIN_SUCCESS, data);

    return Promise.resolve();
  } catch (e) {
    commit(LOGIN_ERROR, e);
    return Promise.reject();
  }
};

const signup = async ({ commit }, userData) => {
  commit(SIGNUP_REQUEST);
  try {
    const { data } = await axios.post(`${apiBaseURL}/register`, {
      name: userData.firstName,
      surname: userData.lastName,
      plot_number: userData.houseNumber,
      email: userData.email,
      phone: userData.phone,
    });

    commit(SIGNUP_SUCCESS, data);
    return data;
  } catch (err) {
    commit(SIGNUP_ERROR, err);
    return Promise.reject(err);
  }
};

const logout = async ({ commit }) => {
  try {
    await axios.post(`${apiBaseURL}/logout`);
  } finally {
    delete axios.defaults.headers.common.Authorization;
    localStorage.removeItem('authToken');
    router.push({ name: 'Home' }).catch(() => {});
    commit(LOGOUT);
  }
  return Promise.resolve();
};

export default {
  [LOGIN_REQUEST]: login,
  [SIGNUP_REQUEST]: signup,
  [LOGOUT]: logout,
};
