<template>
  <v-dialog :value="value" @input="$emit('input', $event)" max-width="500px">
    <v-card :loading="isFolderBeingDeleted">
      <v-card-title class="pt-5 pb-5">{{ $locale.confirmDeleteFolder }}</v-card-title>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn large depressed @click="closeDeleteFolder" class="px-4">{{ $locale.cancel }}</v-btn>
        <v-btn color="error" large depressed @click="deleteFolder" class="px-4">{{ $locale.delete }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DELETE_FOLDER } from '@/store/folders/types';

export default {
  data: () => ({
    isFolderBeingDeleted: false,
  }),
  props: ['folder', 'value'],
  methods: {
    async deleteFolder() {
			this.isFolderBeingDeleted = true;
			await this.$store.dispatch(DELETE_FOLDER, this.folder.id);
			this.closeDeleteFolder();
		},
		closeDeleteFolder() {
      this.isFolderBeingDeleted = false;
			this.$emit('input', false);
      this.$emit('close');
		},
  },
}
</script>

<style>

</style>