import axios from 'axios';
import {
  GET_PAGE,
  GET_PAGE_SUCCESS,
  GET_PAGE_ERROR,
  ADD_PAGE,
  ADD_PAGE_SUCCESS,
  ADD_PAGE_ERROR,
  EDIT_PAGE,
  EDIT_PAGE_SUCCESS,
  EDIT_PAGE_ERROR,
  DELETE_PAGE,
  DELETE_PAGE_SUCCESS,
  DELETE_PAGE_ERROR,
  CLEAR_PAGE_STATE,
} from './types';
import { GET_MENU } from '../app/types';
import { TOGGLE_PAGE_404 } from '../app/types';

const apiBaseURL = process.env.VUE_APP_API_URL;

const getPage = async ({ commit, dispatch }, id) => {
  commit(GET_PAGE);
  try {
    const { data } = await axios.get(`${apiBaseURL}/page/${id}`);
    commit(GET_PAGE_SUCCESS, data);
  } catch (e) {
    commit(GET_PAGE_ERROR, e);
    if(e.response.status) {
      dispatch(TOGGLE_PAGE_404, true);
    }
  }
};

const addPage = async ({ commit, dispatch }, page) => {
  commit(ADD_PAGE);
  try {
    const { data } = await axios.post(`${apiBaseURL}/page`, page);
    commit(ADD_PAGE_SUCCESS, data);
    dispatch(GET_MENU);
    return Promise.resolve();
  } catch (e) {
    commit(ADD_PAGE_ERROR, e);
    return Promise.reject(e);
  }
};

const editPage = async ({ commit, dispatch }, page) => {
  commit(EDIT_PAGE);
  try {
    const { data } = await axios.post(`${apiBaseURL}/page/${page.id}`, {
      ...page,
      _method: 'PUT',
    });
    commit(EDIT_PAGE_SUCCESS, data);
    dispatch(GET_MENU);
    return Promise.resolve();
  } catch (e) {
    commit(EDIT_PAGE_ERROR, e);
    return Promise.reject(e);
  }
};

const deletePage = async ({ commit, dispatch }, id) => {
  commit(DELETE_PAGE);
  try {
    const { data } = await axios.post(`${apiBaseURL}/page/${id}`, { _method: 'DELETE' });
    dispatch(GET_MENU);
    commit(DELETE_PAGE_SUCCESS, data);
  } catch (e) {
    commit(DELETE_PAGE_ERROR, e);
  }
};

const clearPageState = async ({ commit }) => {
  commit(CLEAR_PAGE_STATE);
};

export default {
  [GET_PAGE]: getPage,
  [ADD_PAGE]: addPage,
  [EDIT_PAGE]: editPage,
  [DELETE_PAGE]: deletePage,
  [CLEAR_PAGE_STATE]: clearPageState,
};
