<template>
  <v-container class="error404 py-8">
    <v-row>
      <v-col cols="12">
        <v-img :src="require('@/assets/images/not_found.svg')" class="mt-8 mb-10" />
        <h2 class="text-h1 mb-2">{{ $locale.error404Title }}</h2>
        <p>{{ $locale.errorOops }}... {{ $locale.error404Description }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { TOGGLE_PAGE_LOADER } from '@/store/app/types';

export default {
  name: 'Error404',
  metaInfo() {
    const title = this.$locale.error404Description;
    return {
      title,
    };
  },
  created() {
    this.$store.dispatch(TOGGLE_PAGE_LOADER, false);
  },
}
</script>

<style lang="scss" scoped>
.error404 {
  max-width: 400px;
  text-align: center;

  h2 {
    color: var(--v-accent-darken1);
    font-weight: 500;
  }
}
</style>