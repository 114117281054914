import {
  GET_NEWS,
  GET_NEWS_SUCCESS,
  GET_NEWS_ERROR,
  LOAD_NEWS,
  LOAD_NEWS_SUCCESS,
  LOAD_NEWS_ERROR,
  GET_ARTICLE,
  GET_ARTICLE_SUCCESS,
  GET_ARTICLE_ERROR,
  ADD_ARTICLE,
  ADD_ARTICLE_SUCCESS,
  ADD_ARTICLE_ERROR,
  EDIT_ARTICLE,
  EDIT_ARTICLE_SUCCESS,
  EDIT_ARTICLE_ERROR,
  DELETE_ARTICLE,
  DELETE_ARTICLE_SUCCESS,
  DELETE_ARTICLE_ERROR,
  CLEAR_ARTICLE_STATE,
} from './types';

export const getters = {
  news: (state) => state.news,
  newsStatus: (state) => state.newsStatus,
  article: (state) => state.article,
  articleStatus: (state) => state.articleStatus,
  addArticleStatus: (state) => state.addArticleStatus,
  editArticleStatus: (state) => state.editArticleStatus,
  deleteArticleStatus: (state) => state.deleteArticleStatus,
};

export const mutations = {
  [GET_NEWS]: (state) => {
    state.newsStatus = 'loading';
  },
  [GET_NEWS_SUCCESS]: (state, payload) => {
    state.news.list = payload.data;
    state.news.pinned = payload.pinned;
    state.news.meta = payload.meta;
    state.newsStatus = 'success';
  },
  [GET_NEWS_ERROR]: (state, e) => {
    state.newsStatus = e;
  },
  [LOAD_NEWS]: (state) => {
    state.loadNewsStatus = 'loading';
  },
  [LOAD_NEWS_SUCCESS]: (state, payload) => {
    state.loadNewsStatus = 'success';
    state.news.list.push(...payload.data);
    state.news.meta = payload.meta;
  },
  [LOAD_NEWS_ERROR]: (state, e) => {
    state.loadNewsStatus = 'error';
    state.loadNewsStatus = e.response;
  },
  [GET_ARTICLE]: (state) => {
    state.articleStatus = 'loading';
  },
  [GET_ARTICLE_SUCCESS]: (state, payload) => {
    state.article = payload.data;
    state.articleStatus = 'success';
  },
  [GET_ARTICLE_ERROR]: (state, e) => {
    state.articleStatus = e;
  },
  [ADD_ARTICLE]: (state) => {
    state.addArticleStatus = 'loading';
  },
  [ADD_ARTICLE_SUCCESS]: (state) => {
    state.addArticleStatus = 'success';
  },
  [ADD_ARTICLE_ERROR]: (state, e) => {
    state.addArticleStatus = e;
  },
  [EDIT_ARTICLE]: (state) => {
    state.editArticleStatus = 'loading';
  },
  [EDIT_ARTICLE_SUCCESS]: (state) => {
    state.editArticleStatus = 'success';
  },
  [EDIT_ARTICLE_ERROR]: (state, e) => {
    state.editArticleStatus = e;
  },
  [DELETE_ARTICLE]: (state) => {
    state.deleteArticleStatus = 'loading';
  },
  [DELETE_ARTICLE_SUCCESS]: (state) => {
    state.deleteArticleStatus = 'success';
  },
  [DELETE_ARTICLE_ERROR]: (state, e) => {
    state.deleteArticleStatus = e;
  },
  [CLEAR_ARTICLE_STATE]: (state) => {
    state.article = {};
    state.articleStatus = '';
    state.addArticleStatus = '';
    state.editArticleStatus = '';
    state.deleteArticleStatus = '';
  },
};

export const state = {
  news: {
    list: [],
    pinned: [],
    meta: {},
  },
  newsStatus: '',
  loadNewsStatus: '',
  article: {},
  articleStatus: '',
  addArticleStatus: '',
  editArticleStatus: '',
  deleteArticleStatus: '',
};
