<template>
  <v-dialog :value="value" @input="$emit('input', $event)" max-width="500px">
    <v-card :loading="isLinkBeingDeleted">
      <v-card-title class="pt-5 pb-5">{{ $locale.confirmDeleteLink }}</v-card-title>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn large depressed @click="closeDeleteLink" class="px-4">{{ $locale.cancel }}</v-btn>
        <v-btn color="error" large depressed @click="deleteLink" class="px-4">{{ $locale.delete }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DELETE_LINK } from '@/store/links/types';

export default {
  data: () => ({
    isLinkBeingDeleted: false,
  }),
  props: ['link', 'value'],
  methods: {
    async deleteLink() {
			this.isLinkBeingDeleted = true;
			await this.$store.dispatch(DELETE_LINK, this.link.id);
			this.closeDeleteLink();
		},
		closeDeleteLink() {
      this.isLinkBeingDeleted = false;
			this.$emit('input', false);
      this.$emit('close');
		},
  },
}
</script>

<style>

</style>