import axios from 'axios';
import {
  GET_LINK,
  GET_LINK_SUCCESS,
  GET_LINK_ERROR,
  ADD_LINK,
  ADD_LINK_SUCCESS,
  ADD_LINK_ERROR,
  EDIT_LINK,
  EDIT_LINK_SUCCESS,
  EDIT_LINK_ERROR,
  DELETE_LINK,
  DELETE_LINK_SUCCESS,
  DELETE_LINK_ERROR,
  CLEAR_LINK_STATE,
} from './types';
import {
  GET_MENU
} from '../app/types';

const apiBaseURL = process.env.VUE_APP_API_URL;

const getdLink = async ({ commit }, id) => {
  commit(GET_LINK);
  try {
    const { data } = await axios.get(`${apiBaseURL}/link/${id}`);
    commit(GET_LINK_SUCCESS, data);
  } catch (e) {
    commit(GET_LINK_ERROR, e);
  }
};

const addLink = async ({ commit, dispatch }, link) => {
  commit(ADD_LINK);
  try {
    const { data } = await axios.post(`${apiBaseURL}/link`, link);
    commit(ADD_LINK_SUCCESS, data);
    dispatch(GET_MENU);
    return Promise.resolve();
  } catch (e) {
    commit(ADD_LINK_ERROR, e);
    return Promise.reject(e);
  }
};

const editdLink = async ({ commit, dispatch }, link) => {
  commit(EDIT_LINK);
  try {
    const { data } = await axios.post(`${apiBaseURL}/link/${link.id}`, {
      ...link,
      _method: 'PUT',
    });
    commit(EDIT_LINK_SUCCESS, data);
    dispatch(GET_MENU);
    return Promise.resolve();
  } catch (e) {
    commit(EDIT_LINK_ERROR, e);
    return Promise.reject(e);
  }
};

const deletedLink = async ({ commit, dispatch }, id) => {
  commit(DELETE_LINK);
  try {
    const { data } = await axios.post(`${apiBaseURL}/link/${id}`, { _method: 'DELETE' });
    dispatch(GET_MENU);
    commit(DELETE_LINK_SUCCESS, data);
  } catch (e) {
    commit(DELETE_LINK_ERROR, e);
  }
};

const cleardLinkState = async ({ commit }) => {
  commit(CLEAR_LINK_STATE);
};

export default {
  [GET_LINK]: getdLink,
  [ADD_LINK]: addLink,
  [EDIT_LINK]: editdLink,
  [DELETE_LINK]: deletedLink,
  [CLEAR_LINK_STATE]: cleardLinkState,
};
