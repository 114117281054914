<template>
<div>
  <v-dialog :value="value" @input="$emit('input', $event)" max-width="400">
    <v-card class="pt-4">
      <v-form @submit.prevent="submit">
        <v-card-title>
          <span class="text-h5">{{ $locale.authFormTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container class="pa-0">
            <v-row>
              <v-col cols="12" v-if="authStatus.type === 'error'">
                <v-alert type="error" class="mb-0">
                  {{ authStatus.error.status === 404 ? $locale.authFormWrongCredentials : $locale.errorBasic }}
                </v-alert>
              </v-col>
              <v-col cols="12">
                <v-text-field :label="$locale.inputUsername" outlined hide-details="auto" v-model="username" />
              </v-col>
              <v-col cols="12">
                <v-text-field :label="$locale.inputPassword" outlined hide-details="auto" type="password" v-model="password" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn large depressed color="primary" type="submit" class="px-4" :loading="isBeingSubmitted">
            {{ $locale.signin }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import { LOGIN_REQUEST } from '@/store/auth/types';

export default {
  name: "PopupFormLogin",
  data: () => ({
    username: '',
    password: '',
    isBeingSubmitted: false,
  }),
  props: {
    value: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    async submit() {
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   return false;
      // }

      this.isBeingSubmitted = true;

      try {
        await this.$store.dispatch(LOGIN_REQUEST, {
          login: this.username,
          password: this.password,
        });
        this.$emit('input', false);
      } catch (e) {
        this.snack = true;
        this.snackColor = 'error';
        this.snackText = 'Упс, щось пішло не так!';
      }
      
      this.isBeingSubmitted = false;
    },
  },
  computed: {
    authStatus() {
      return this.$store.getters.authStatus;
    },
  },
};
</script>

<style>
</style>