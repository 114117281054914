import {
  GET_MENU,
  GET_MENU_SUCCESS,
  GET_MENU_ERROR,
  UPDATE_MENU,
  UPDATE_MENU_SUCCESS,
  UPDATE_MENU_ERROR,
  TOGGLE_PAGE_LOADER,
  TOGGLE_PAGE_404,
} from './types';

export const getters = {
  menu: (state) => state.menu,
  menuStatus: (state) => state.menuStatus,
  updateMenuStatus: (state) => state.updateMenuStatus,
  isPageLoading: (state) => state.isPageLoading,
  isPage404: (state) => state.isPage404,
};

export const mutations = {
  [GET_MENU]: (state) => {
    state.menuStatus = 'loading';
  },
  [GET_MENU_SUCCESS]: (state, payload) => {
    const iterateThroughItems = (items) => {
      items.forEach((item) => {
        if (item.type === 'folder') {
          item.isOpened = false;
          iterateThroughItems(item.items);
        }
      });
    };
    iterateThroughItems(payload);

    state.menu = payload;
    state.menuStatus = 'success';
  },
  [GET_MENU_ERROR]: (state, e) => {
    state.menuStatus = e;
  },
  [UPDATE_MENU]: (state) => {
    state.updateMenuStatus = 'loading';
  },
  [UPDATE_MENU_SUCCESS]: (state) => {
    state.updateMenuStatus = 'success';
  },
  [UPDATE_MENU_ERROR]: (state, e) => {
    state.updateMenuStatus = e;
  },
  [TOGGLE_PAGE_LOADER]: (state, payload) => {
    state.isPageLoading = payload;
  },
  [TOGGLE_PAGE_404]: (state, payload) => {
    state.isPage404 = payload;
  },
};

export const state = {
  menu: [],
  menuStatus: '',
  updateMenuStatus: '',
  isPageLoading: '',
  isPage404: '',
};
