<template>
  <v-app>
    <PageLoader :visible="isPageLoading || menuStatus === 'loading'" />
    <v-system-bar app absolute color="primary" height="0" class="pt-2 system_bar"></v-system-bar>
    <v-app-bar v-if="isMobile" app flat absolute>
      <router-link :to="{ name: 'Home' }" class="header_homelink">
        <v-img :src="require('@/assets/images/logo.svg')" :max-width="$vuetify.breakpoint.xs ? 40 : 60" class="flex-grow-0" />
        <h1 class="text-subtitle-2 text-sm-h6 ml-4 ml-sm-6">
          {{ $locale.projectName }}
        </h1>
      </router-link>
      <v-app-bar-nav-icon @click="nav = !nav" class="ml-auto"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer  app v-model="nav" :right="isMobile" color="subtle" floating
                          width="320" class="nav">
      <div class="pt-2"></div>
      <v-btn @click="isPopupFormLoginVisible = true" class="nav_singin"></v-btn>
      <v-sheet class="pt-4" color="transparent">
        <router-link v-if="!isMobile" :to="{ name: 'Home' }" class="px-6 mb-6 header_homelink flex-column">
          <v-img :src="require('@/assets/images/logo.svg')" max-width="100" />
          <h1 class="text-h5 mt-4">
            {{ $locale.projectName }}
          </h1>
        </router-link>
        <Nav 
          @editLink="openPopupFormLink"
          @editFolder="openPopupFormFolder"
          @deleteFolder="deleteFolder"
          @deleteLink="deleteLink"
          @deletePage="deletePage"
          :isAdmin="isAdmin"
        />
      </v-sheet>
      <div class="nav_actions" v-if="isAdmin">
        <v-menu top left offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab color="primary" elevation="2" small class="nav_actions_btnAdd" v-bind="attrs" v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item :to="{ name: 'AddPage' }">
              <v-list-item-title>{{ $locale.addMenuList.page }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="openPopupFormLink">
              <v-list-item-title>{{ $locale.addMenuList.link }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="openPopupFormFolder">
              <v-list-item-title>{{ $locale.addMenuList.folder }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-navigation-drawer>
    
    <v-main class="content">
      <Error404 v-if="isPage404" />
      <router-view :key="$route.path" v-else />
    </v-main>

    <v-footer absolute app padless inset>
      <v-card flat tile class="primary white--text text-center flex-grow-1">
        <v-card-text class="white--text">
          {{ $locale.projectNameFull }}
        </v-card-text>
<!-- 
        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Vuetify</strong>
        </v-card-text> -->
      </v-card>
    </v-footer>

    <PopupFormLogin v-model="isPopupFormLoginVisible" />
    <PopupFormLink v-if="isAdmin" v-model="isPopupFormLinkVisible" :link="linkToEdit" @input="handlerPopupFormLink" />
    <PopupFormFolder v-if="isAdmin" v-model="isPopupFormFolderVisible" :folder="folderToEdit" @input="handlerPopupFormFolder" />
    <PopupConfirmDeleteFolder v-if="isAdmin" v-model="isPopupConfirmDeleteFolderVisible" :folder="folderToDelete" />
    <PopupConfirmDeleteLink v-if="isAdmin" v-model="isPopupConfirmDeleteLinkVisible" :link="linkToDelete" />
    <PopupConfirmDeletePage v-if="isAdmin" v-model="isPopupConfirmDeletePageVisible" :page="pageToDelete" />
  </v-app>
</template>

<script>
import Error404 from '@/views/Error404.vue';
import PageLoader from '@/components/PageLoader.vue';
import Nav from '@/components/Nav.vue';
import PopupFormLogin from '@/components/PopupFormLogin.vue';
import PopupFormLink from '@/components/PopupFormLink.vue';
import PopupFormFolder from '@/components/PopupFormFolder.vue';
import PopupConfirmDeleteFolder from '@/components/PopupConfirmDeleteFolder.vue';
import PopupConfirmDeleteLink from '@/components/PopupConfirmDeleteLink.vue';
import PopupConfirmDeletePage from '@/components/PopupConfirmDeletePage.vue';
import { TOGGLE_PAGE_LOADER } from '@/store/app/types';

export default {
  metaInfo: {
    titleTemplate(titleChunk) {
      const { projectName } = this.$locale;
      return titleChunk ? `${titleChunk} | ${projectName}` : projectName;
    },
  },
  data: () => ({
    nav: null,
    isNavAddMenuVisible: false,
    isPopupFormLoginVisible: null,
    isPopupFormLinkVisible: null,
    isPopupFormFolderVisible: null,
    linkToEdit: null,
    folderToEdit: null,
    isPopupConfirmDeleteFolderVisible: false,
    folderToDelete: '',
    isPopupConfirmDeleteLinkVisible: false,
    linkToDelete: '',
    isPopupConfirmDeletePageVisible: false,
    pageToDelete: '',
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    isAdmin() {
      return this.$store.getters.isAuthenticated;
    },
    isPageLoading() {
      return this.$store.getters.isPageLoading;
    },
    isPage404() {
      return this.$store.getters.isPage404;
    },
    menuStatus() {
      return this.$store.getters.menuStatus;
    },
  },
  methods: {
    openPopupFormLink(link) {
      if (link.id) {
        this.linkToEdit = link;
      }
      this.isPopupFormLinkVisible = true;
    },
    handlerPopupFormLink($event) {
      if ($event === false) {
        this.linkToEdit = null;
      }
    },
    openPopupFormFolder(folder) {
      if (folder.id) {
        this.folderToEdit = folder;
      }
      this.isPopupFormFolderVisible = true;
    },
    handlerPopupFormFolder($event) {
      if ($event === false) {
        this.folderToEdit = null;
      }
    },
    deleteFolder($event) {
      this.folderToDelete = $event;
      this.isPopupConfirmDeleteFolderVisible = true;
    },
    deleteLink($event) {
      this.linkToDelete = $event;
      this.isPopupConfirmDeleteLinkVisible = true;
    },
    deletePage($event) {
      this.pageToDelete = $event;
      this.isPopupConfirmDeletePageVisible = true;
    },
  },
  components: {
    Nav,
    PopupFormLogin,
    PopupFormLink,
    PopupFormFolder,
    PopupConfirmDeleteFolder,
    PopupConfirmDeleteLink,
    PopupConfirmDeletePage,
    PageLoader,
    Error404,
  },
  created() {
    this.$store.dispatch(TOGGLE_PAGE_LOADER, true);
  },
}
</script>

<style lang="scss" scoped>
.system_bar {
  z-index: 10;
}
.header_homelink {
  display: flex;
  flex-shrink: 0;
  color: currentColor;
  text-decoration: none;
  align-items: center;
}

::v-deep {
  .nav .v-navigation-drawer__content {
   overflow-y: scroll; 
  }
  .v-navigation-drawer--is-mobile {
    .nav_singin {
      top: -8px;
      margin-left: auto;
    }
  }
}
.nav {
  top: 0!important;
  max-height: 100%!important;

  ::-webkit-scrollbar {
    width: 16px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: transparent;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: #BDBDBD;
    }
  }
}
.nav_actions {
  justify-content: flex-end;
  display: flex;
  position: sticky;
  padding: 0 24px 24px;
  bottom: 0;
  pointer-events: none;

  .v-btn {
    pointer-events: all;
  }
}
.nav_singin {
  height: 40px!important;
  width: 40px!important;
  padding: 0!important;
  min-width: unset!important;
  cursor: default;
  margin-bottom: -40px;
  display: block;
  z-index: 15;
  top: 0;
  left: 0;
  border-radius: 0;
  opacity: 0;
}

.content {
  margin-bottom: 100px;
}
</style>
