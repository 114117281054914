<template>
  <v-dialog :value="value" @input="$emit('input', $event)" max-width="500px">
    <v-card :loading="isPageBeingDeleted">
      <v-card-title class="pt-5 pb-5">{{ $locale.confirmDeletePage }}</v-card-title>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn large depressed @click="closeDeletePage" class="px-4">{{ $locale.cancel }}</v-btn>
        <v-btn color="error" large depressed @click="deletePage" class="px-4">{{ $locale.delete }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DELETE_PAGE } from '@/store/pages/types';

export default {
  data: () => ({
    isPageBeingDeleted: false,
  }),
  props: ['page', 'value'],
  methods: {
    async deletePage() {
			this.isPageBeingDeleted = true;
			await this.$store.dispatch(DELETE_PAGE, this.page.id);
      this.$emit('deleted');
			this.closeDeletePage();
		},
		closeDeletePage() {
      this.isPageBeingDeleted = false;
			this.$emit('input', false);
      this.$emit('close');
		},
  },
}
</script>

<style>

</style>