import axios from 'axios';
import {
  GET_FOLDER,
  GET_FOLDER_SUCCESS,
  GET_FOLDER_ERROR,
  ADD_FOLDER,
  ADD_FOLDER_SUCCESS,
  ADD_FOLDER_ERROR,
  EDIT_FOLDER,
  EDIT_FOLDER_SUCCESS,
  EDIT_FOLDER_ERROR,
  DELETE_FOLDER,
  DELETE_FOLDER_SUCCESS,
  DELETE_FOLDER_ERROR,
  CLEAR_FOLDER_STATE,
} from './types';
import {
  GET_MENU
} from '../app/types';

const apiBaseURL = process.env.VUE_APP_API_URL;

const getdFolder = async ({ commit }, id) => {
  commit(GET_FOLDER);
  try {
    const { data } = await axios.get(`${apiBaseURL}/folder/${id}`);
    commit(GET_FOLDER_SUCCESS, data);
  } catch (e) {
    commit(GET_FOLDER_ERROR, e);
  }
};

const addFolder = async ({ commit, dispatch }, folder) => {
  commit(ADD_FOLDER);
  try {
    const { data } = await axios.post(`${apiBaseURL}/folder`, folder);
    commit(ADD_FOLDER_SUCCESS, data);
    dispatch(GET_MENU);
    return Promise.resolve();
  } catch (e) {
    commit(ADD_FOLDER_ERROR, e);
    return Promise.reject(e);
  }
};

const editdFolder = async ({ commit, dispatch }, folder) => {
  commit(EDIT_FOLDER);
  try {
    const { data } = await axios.post(`${apiBaseURL}/folder/${folder.id}`, {
      ...folder,
      _method: 'PUT',
    });
    commit(EDIT_FOLDER_SUCCESS, data);
    dispatch(GET_MENU);
    return Promise.resolve();
  } catch (e) {
    commit(EDIT_FOLDER_ERROR, e);
    return Promise.reject(e);
  }
};

const deletedFolder = async ({ commit, dispatch }, id) => {
  commit(DELETE_FOLDER);
  try {
    const { data } = await axios.post(`${apiBaseURL}/folder/${id}`, { _method: 'DELETE' });
    dispatch(GET_MENU);
    commit(DELETE_FOLDER_SUCCESS, data);
  } catch (e) {
    commit(DELETE_FOLDER_ERROR, e);
  }
};

const cleardFolderState = async ({ commit }) => {
  commit(CLEAR_FOLDER_STATE);
};

export default {
  [GET_FOLDER]: getdFolder,
  [ADD_FOLDER]: addFolder,
  [EDIT_FOLDER]: editdFolder,
  [DELETE_FOLDER]: deletedFolder,
  [CLEAR_FOLDER_STATE]: cleardFolderState,
};
